import React from 'react'
import { navigate, graphql, useStaticQuery } from 'gatsby'

import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Meta from 'components/meta'
import Wrapper from 'components/wrapper'

import { useAirtableWorks } from 'utils/use-airtable-works'

export default function PortfolioWorkVideos({ location }) {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        filter: {
          frontmatter: { media: { elemMatch: { type: { eq: "video" } } } }
          contentType: { eq: "works" }
        }
        sort: { fields: contentKey }
      ) {
        nodes {
          contentKey
          frontmatter {
            media {
              label
              route
              type
            }
            title
          }
        }
      }
    }
  `)
  const airtable = useAirtableWorks()

  // TODO: replace with VideoGrid
  const renderVideoButton = (node, media) => {
    const workItem = airtable.find((o) => o.data.workId == node.contentKey)?.data
    return (
      <Button
        className="text-truncate"
        variant="outline-primary"
        onClick={() => navigate(`/${node.contentKey}/${media.route}/`)}
      >
        {workItem?.title}
        {media.label && <span> - {media.label}</span>}
      </Button>
    )
  }

  return (
    <Wrapper location={location} variant="cover" bodyClass="bg-body-darker">
      <Meta title="Video Portfolio" />
      <Card className="w-75 mx-auto border-0">
        <Card.Header className="w-100 mb-3">
          <div className="mx-auto text-primary fs-4">Video Portfolio</div>
        </Card.Header>
        <Row className="g-1 justify-content-center">
          {data.allMdx?.nodes?.map((node, nodeIndex) =>
            node.frontmatter?.media?.map(
              (media, mediaIndex) =>
                media?.type === 'video' && (
                  <Col xs="auto" key={`${nodeIndex}-${mediaIndex}`}>
                    {renderVideoButton(node, media)}
                  </Col>
                )
            )
          )}
        </Row>
      </Card>
    </Wrapper>
  )
}

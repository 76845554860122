import { useStaticQuery, graphql } from 'gatsby'

export const useAirtableWorks = () => {
  const { allAirtable } = useStaticQuery(graphql`
    query {
      allAirtable(
        filter: { table: { eq: "works" }, data: { portfolio: { eq: true } } }
        limit: 9999
      ) {
        nodes {
          data {
            tags
            title
            workId: work_id
            year
          }
        }
      }
    }
  `)
  return allAirtable?.nodes
}
